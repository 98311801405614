import request from '@http';
// 获取房源列表
export function getHouseAllListApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/build/all/apartment',
        params,
    });
}
// 获取房源列表,状态数量
export function getHouseBycountApi(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/build/all/apartment/count',
        params,
    });
}
// 添加房源
export function addHouseApi(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/baseinfo/apartment/',
        data,
    });
}
// 获取房源详情
export function getHouseDetailApi(uuid) {
    return request({
        method: 'Get',
        url: '/api/gzf/baseinfo/apartment/' + uuid,
    });
}
// 修改房源
export function putHouseDetailApi(uuid,data) {
    return request({
        method: 'PUT',
        url: `/api/gzf/baseinfo/apartment/${uuid}/update`,
        data
    });
}
// 获取房源所有合同
export function getHouseContractApi(params) {
    return request({
        method: 'Get',
        url: `/api/gzf/ca/list`,
        params,
    });
}
// 获取合同详情
export function getHouseContractDetailApi(uuid) {
    return request({
        method: 'Get',
        url: `/api/gzf/contract/` + uuid,
    });
}
// 获取用户未处理账单
export function getHouseLeaserUnPaidApi(params) {
    return request({
        method: 'Get',
        url: `/api/gzf/baseinfo/leasor/${params.uuid}/bills/rental/unpaid`,
        params,
    });
}
// 获取用户已处理账单
export function getHouseLeaserPaidApi(params) {
    return request({
        method: 'Get',
        url: `/api/gzf/baseinfo/leasor/${params.uuid}/bills/rental/paid`,
        params,
    });
}
//2022/03/21 王江毅 获取用户未处理杂费账单
export function getHouseLeaserOtherBillsUnPaidApi(params) {
    return request({
        method: 'Get',
        url: `/api/gzf/other/bill/${params.uuid}/unpaid`,
        params
    });
}
//2022/03/21 王江毅 获取用户已处理杂费账单
export function getHouseLeaserOtherBillsPaidApi(params) {
    return request({
        method: 'Get',
        url: `/api/gzf/other/bill/${params.uuid}/paid`,
        params,
    });
}
//2022/03/21 王江毅 添加杂费账单
export function addOtherBills(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/other/bill/create',
        data,
    });
}
//2022/04/21 王江毅 修改杂费账单
export function updateOtherBills(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/other/bill/update',
        data,
    });
}
//2022/03/31 王江毅 根据房源uuid,承租人uuid查询房源
export function getApartmentData(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/ca/apartment`,
        params,
    });
}
//2022/04/02 王江毅 创建合同
export function setUserContract(data) {
    return request({
        method: 'PUT',
        url: `/api/gzf/rent/user/contract`,
        data,
    });
}
//2022/03/31 王江毅 设置房源信息
export function setApartmentData(data) {
    return request({
        method: 'PUT',
        url: `/api/gzf/ca/sign/amount`,
        data,
    });
}
//2022/04/05 王江毅 添加房租账单
export function addBills(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/ca/bill/create',
        data,
    });
}
//2022/04/21 王江毅 修改房租账单
export function updateBills(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/ca/bill/update',
        data,
    });
}
//2022/04/07 王江毅 设置优惠价
export function setApartmentAmount(params) {
    return request({
        method: 'get',
        url: '/api/gzf/set/apartment/amount',
        params,
    });
}
//2022/04/07 王江毅 核销
export function deposit(uuid,data) {
    return request({
        method: 'PUT',
        url: `/api/gzf/baseinfo/leasor/${uuid}/deposit`,
        data,
    });
}
//2022/04/07 王江毅 充值
export function getDeposit(params) {
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/leasor/${params.uuid}/deposit`,
        params,
    });
}
//2022/04/07 王江毅 催款 /api/gzf/ca/reminder?uuid=
export function reminder(params) {
    return request({
        method: 'get',
        url: `/api/gzf/ca/reminder`,
        params,
    });
}
//2022/05/04 王江毅 根据房源名获取空置的房源
export function renewalByName(params) {
    return request({
        method: 'get',
        url: `/api/gzf/renewal/name`,
        params,
    });
}
// 2022/05/05 王江毅 换房
export function contractChange(data) {
    return request({
        method: 'put',
        url: `/api/gzf/renewal/contract/change`,
        data,
    });
}
//2022/05/06 王江毅 押金核销
export function paydeposit(uuid) {
    return request({
        method: 'put',
        url: `/api/gzf/baseinfo/leasor/${uuid}/paydeposit`,
    });
}
//2022/05/06 王江毅 通过获取用户信息
export function getLeasorId(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/leasor/getLeasorId/${uuid}`,
    });
}
//2022/05/06 王江毅 查询换房详情页面
export function getPenewalChangeDetails(data) {
    return request({
        method: 'post',
        url: `/api/gzf/renewal/room/change/details`,
        data,
    });
}
//2022/05/06 王江毅 换房提交接口
export function renewalRoomChangeSave(data) {
    return request({
        method: 'post',
        url: `/api/gzf/renewal/room/change/save`,
        data,
    });
}
//2022/11/21 王江毅 根据房间修改月、季、年房间金额
export function apartmentAmountUpdate(data) {
    return request({
        method: 'put',
        url: `/api/gzf/baseinfo/apartment/amount/update`,
        data,
    });
}
//2022/11/21 王江毅 获取房间金额及房型
export function getApartmentDetails(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/apartment/details/${uuid}`,
    });
}

//2023/01/12 王江毅 拆分房间
export function apartmentSplit(params) {
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/apartment/apartment/split`,
        params,
    });
}

//2023/01/12 王江毅 查询所有子房间
export function apartmentSon(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/apartment/apartment/son/${uuid}`,
    });
}

//2023/01/12 王江毅 判断房源是保租房还是富航
export function isbzf(uuid) {
    return request({
        method: 'get',
        url: `/api/new/contract/apartment/isbzf/${uuid}`,
    });
}

//2023/01/12 王江毅 保租房签约查询金额
export function getBzfAmount(params) {
    return request({
        method: 'get',
        url: `/api/new/contract/bzg/sign/amount`,
        params
    });
}

//2023/01/16 王江毅 保租房更改房间单价折扣房型
export function apartmentDiscountAmountUpdate(data) {
    return request({
        method: 'post',
        url: `/api/gzf/baseinfo/apartment/discount/amount/update`,
        data
    });
}

//2023/01/16 王江毅 获取房间单价折扣房型
export function apartmentDiscountAmountInfo(params) {
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/apartment/get/discount/amount/info`,
        params
    });
}

//2023/01/16 王江毅 获取房间所有计算后金额
export function bzfGetAmount(params) {
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/apartment/get/discount/amount/info/all`,
        params
    });
}

//2023/02/06 王江毅 根据小区获取房间
export function getApartmentsByCommunity(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/apartment/get/all/community/${uuid}`,
    });
}

//2023/03/15 王江毅 查询房间备案信息
export function getRecordInfo(params) {
    return request({
        method: 'get',
        url: `/api/gzf/zjj/get/info`,
        params,
    });
}

//2023/03/15 王江毅 查询核验信息
export function getRecordHouseInfo(params) {
    return request({
        method: 'get',
        url: `/api/gzf/zjj/get/houseInfo`,
        params,
    });
}

//2023/03/15 王江毅 发起住建局房间核验
export function getRecordHouseAutoCheck(data) {
    return request({
        method: 'post',
        url: `/api/gzf/zjj/houseAutoCheck`,
        data,
    });
}

//2023/03/15 王江毅 核验结果查询
export function getRecordArtiCheckResult(params) {
    return request({
        method: 'get',
        url: `/api/gzf/zjj/get/artiCheckResult`,
        params,
    });
}

// 2023/03/22 王江毅 核验码核验
export function getHousingCodeInfo(params) {
    return request({
        method: 'get',
        url: `/api/gzf/zjj/housingCode/info`,
        params,
    });
}

// 2023/03/23 王江毅 获取保租房项目列表
export function getBzfProject() {
    return request({
        method: 'get',
        url: `/api/gzf/zjj/getBzfProject/list`,
    });
}

// 2023/03/23 王江毅 设置保租房项目
export function bzfProjectPut(params) {
    return request({
        method: 'get',
        url: `/api/gzf/zjj/getBzfProject/put`,
        params,
    });
}

// 2023/08/03 张晓瑜 房源状态导航统计
export function getCountListAPI(params) {
    return request({
        method:'get',
        url: `/api/gzf/build/all/apartment/countByKeyword`,
        params
    })
}
//2023/11/02 张晓瑜 修改房间信息详情
export function getUpdateApartmentDetails(uuid) {
    return request({
        method: 'get',
        url: `/api/gzf/baseinfo/apartment/update/get/${uuid}`,
    });
}
// 2024-2-5-陈守亮-充值
export function czhiputApi(uuid,data) {
    return request({
        method: 'post',
        url: `/api/gzf/baseinfo/leasor/topup/${uuid}/balance`,
        data,
    });
}
export const getCzhiListApi = (params) => {
    return request({
        method: 'get',
        url: '/api/gzf/baseinfo/apartment/rental_type/info',
        params
    })
}
// 合同签约发起审批
export const hetongshengpiGET = (data) => {
    return request({
        method: 'post',
        url: `/api/gzf/contract/approval/submit`,
        data
    })
}
//企业签约的完善下载合同模板
export const hetongshengpGET = (data) => {
    return request({
        method: 'get',
        url: `/api/nuonuo/contract/expord/qy/pdf/${data.uuid}`,
        responseType: 'blob',
        data
    })
}
