<template>
    <r-e-dialog title="分配房间" show-footer :visible.sync="dialogVisible" top="5vh" width="500px"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="80px" size="small" :model="formPublish"
                 :rules="rules">
                <el-form-item label="资产编码">
                    <el-input v-model="formPublish.asstesSn" disabled style="width: 350px;"/>
                </el-form-item>
                <el-form-item label="小区">
                    <el-select v-model="communityUuid" filterable placeholder="请输入小区" @change="communityChange"
                               style="width: 350px;">
                        <el-option v-for="item in communityList" :key="item.uuid" :label="item.name" :value="item.uuid"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="房间" prop="apartmentUuid">
                    <el-select v-model="formPublish.apartmentUuid" filterable clearable collapse-tags style="width: 350px;"
                                placeholder="请输入房间" v-loading="apartmentLoading">
                        <el-option v-for="item in apartmentUuidsList" :key="item.uuid" :label="item.name" :value="item.uuid"/>
                    </el-select>
                </el-form-item>
        </el-form>
    </r-e-dialog>
</template>
<script>
import {getCommunityListApi} from "@/api/community-management"
import {getApartmentsByCommunity} from "@/api/house-resource";
import {allocate} from "@/api/assets";
import {MessageInfo, MessageSuccess} from "@custom/message";
export default {
    name: "layer-allocation-room",
    data() {
        return {
            dialogVisible: false,
            formPublish: {
                asstesSn: null,
                assetDetailUuid: null,
                apartmentUuid: null
            },
            rules: {
                apartmentUuid: [{required: true, message: '请选择分配房间', trigger: 'change'}],
            },
            communityUuid: null,
            communityList: [],
            apartmentUuidsList: [],
            apartmentLoading: false,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        }
    },
    components: {},
    methods: {
        async openDialog(data) {
            let that = this;
            that.formPublish.assetDetailUuid = data.uuid;
            that.formPublish.asstesSn = data.asstesSn;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            let {list: communityList} = await getCommunityListApi().finally(() => loading.close());
            communityList = communityList.filter(({apartmentsCount}) => apartmentsCount > 0);
            communityList = communityList.map(({name, uuid}) => { return {name, uuid} });
            this.communityList = communityList;
            loading.close();
            // that.renewalByName('95257563f370482db96d74ab2af20dde');
            that.dialogVisible = true;
        },
        clickSubmit() {
            let that = this;
            this.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    let {assetDetailUuid, apartmentUuid} = that.formPublish;
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    allocate({assetDetailUuid, apartmentUuid}).then(res => {
                        MessageSuccess('分配成功');
                        that.clickCancel();
                    }).finally(() => loading.close());
                }
            });
        },
        clickCancel() {
            this.$refs["formPublish"].resetFields();
            this.$emit("handleSearch", false);
            this.dialogVisible = false;
        },
        communityChange(v){
            this.renewalByName(v);
        },
        async renewalByName(communityUuid) {
            this.apartmentLoading = true;
            this.formPublish.apartmentUuid = null;
            let {list} = await getApartmentsByCommunity(communityUuid);
            this.apartmentUuidsList = list;
            this.apartmentLoading = false;
        },
    },
    async mounted() {

    },
    watch: {

    }
}
</script>
<style lang="scss" scoped>
.title {
    position: relative;
    padding-left: 10px;
    font-size: 18px;

    &::before {
        width: 5px;
        height: 100%;
        content: '';
        background-color: #F5A623;
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>